<template>
  <div>
    <h1 class="title">Нет такой страницы!</h1>
    <p>Перейти
      <router-link class="link" :to="{ name: 'News' }">в новости?</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "Error404Page"
}
</script>

<style scoped>

</style>